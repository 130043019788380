import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import authFetch from "../axios";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { useGetFileUrl } from "../helpers/hooks/useGetFIleUrl";
import { Dialog } from 'primereact/dialog';

const DeathCertificateReissuanceDetails = () => {
    const { id } = useParams();
    const [details, setDetails] = useState(null);
    const [faceFileKey, setFaceFileKey] = useState("");
    const [documentFileKey, setDocumentFileKey] = useState("");
    const [faceUrl, setFaceUrl] = useState("");
    const [documentUrl, setDocumentUrl] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [modalType, setModalType] = useState("");
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isApproving, setIsApproving] = useState(false);

    const [isQueryModalOpen, setIsQueryModalOpen] = useState(false);
    const [queryReason, setQueryReason] = useState("");
    const [isQuerying, setIsQuerying] = useState(false);


    const { getUrl: getFileUrl, isLoadingUrl: isLoadingFileUrl } = useGetFileUrl();

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    // Function to fetch certificate details
    const fetchDetails = useCallback(async () => {
        try {
            setIsLoading(true);
            setHasError(false);
            const payload = {
                certType: "birth_reissuance",
                id: Number(id),
            };
            const response = await authFetch.post(`/certificate-reissuance/single`, payload);

            const data = response?.data?.data;
            setDetails(data);

            // Set keys from the response
            setFaceFileKey(data?.childFace);
            setDocumentFileKey(data?.supportingDocument?.[0]?.file);
        } catch (error) {
            console.error("Error fetching details:", error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        fetchDetails();
    }, [fetchDetails, id]);

    useEffect(() => {
        const fetchFileUrls = async () => {
            try {
                // Fetch face file URL
                if (faceFileKey) {
                    const faceUrl = await getFileUrl(faceFileKey);
                    setFaceUrl(faceUrl);
                }

                // Fetch document file URL
                if (documentFileKey) {
                    const docUrl = await getFileUrl(documentFileKey);
                    setDocumentUrl(docUrl);
                }
            } catch (error) {
                console.error("Error fetching file URLs:", error);
            }
        };

        fetchFileUrls();
    }, [faceFileKey, documentFileKey, getFileUrl]);

    const handleApprove = async () => {
        setIsApproving(true);
        try {
            const payload = {
                certType: "birth_reissuance",
                id: Number(id),
                action: "approve",
            };
            await authFetch.post(`/certificate-reissuance/approve`, payload);

            setIsApproveModalOpen(false);
            setIsSuccessModalOpen(true);

            // Fetch details again after approving
            await fetchDetails();
        } catch (error) {
            console.error("Error approving registration:", error);
        } finally {
            setIsApproving(false);
        }
    };

    const handleReject = () => {
        setIsApproveModalOpen(false);
        setIsQueryModalOpen(true);
    };

    const handleQuerySubmit = async () => {
        setIsQuerying(true);
        try {
            const payload = {
                certType: "birth_reissuance",
                id: Number(id),
                reason: queryReason,
            };
            await authFetch.post(`/certificate-reissuance/reject`, payload);

            // Show success modal
            setIsQueryModalOpen(false);
            setIsSuccessModalOpen(true);

            // Refresh details after rejection
            await fetchDetails();
        } catch (error) {
            console.error("Error querying registration:", error);
        } finally {
            setIsQuerying(false);
        }
    };

    const renderDataField = (label, value) => (
        <div className="col-12 md:col-3 mb-2">
            <div className="text-600 text-xs">{label}</div>
            <div className="text-900 font-medium">
                {value ? (
                    value
                ) : (
                    <span className="text-gray-500 text-xs italic flex items-center">
                        <i className="pi pi-exclamation-triangle mr-1 text-red-500" style={{ fontSize: '1rem' }}></i>
                        Data not provided
                    </span>
                )}
            </div>
        </div>
    );

    if (isLoading) {
        return (
            <div className="px-2">
                <Button
                    label="Back"
                    icon="pi pi-arrow-left"
                    className="p-button-text p-button-secondary mb-2 p-button-rounded"
                    onClick={goBack}
                />
                <div className="px-4 py-4 shadow-2 border-round surface-0 mb-4">
                    <Skeleton borderRadius="16px" className="mb-2" width="50%" height="1.5rem" />
                    <Skeleton borderRadius="16px" className="mb-6" width="30%" height="0.5rem" />
                    <div className="grid">
                        {Array(8).fill(null).map((_, i) => (
                            <div className="col-12 md:col-3 mb-4" key={i}>
                                <Skeleton borderRadius="16px" width="100%" height="1.5rem" className="mb-2" />
                                <Skeleton borderRadius="16px" width="80%" height="1rem" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="px-4 py-4 shadow-2 border-round surface-0 mb-4">
                    <Skeleton borderRadius="16px" className="mb-2" width="50%" height="1.5rem" />
                    <Skeleton borderRadius="16px" className="mb-6" width="30%" height="0.5rem" />
                    <div className="grid">
                        {Array(8).fill(null).map((_, i) => (
                            <div className="col-12 md:col-3 mb-4" key={i}>
                                <Skeleton borderRadius="16px" width="100%" height="1.5rem" className="mb-2" />
                                <Skeleton borderRadius="16px" width="80%" height="1rem" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="px-4 py-4 shadow-2 border-round surface-0 mb-4">
                    <Skeleton borderRadius="16px" className="mb-2" width="50%" height="1.5rem" />
                    <Skeleton borderRadius="16px" className="mb-6" width="30%" height="0.5rem" />
                    <div className="grid">
                        {Array(8).fill(null).map((_, i) => (
                            <div className="col-12 md:col-3 mb-4" key={i}>
                                <Skeleton borderRadius="16px" width="100%" height="1.5rem" className="mb-2" />
                                <Skeleton borderRadius="16px" width="80%" height="1rem" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="px-2">
                <Button label="Back" icon="pi pi-arrow-left" className="p-button-text p-button-secondary mb-2 p-button-rounded" onClick={goBack} />
                <p className="text-red-500">Failed to load details. Please try again later.</p>
            </div>
        );
    }

    return (
        <>
            {/* Approve Modal */}
            <Dialog
                visible={isApproveModalOpen}
                style={{ width: "50vw", borderRadius: "10px" }}
                onHide={() => setIsApproveModalOpen(false)}
                className="flex flex-col items-center"
            >
                <div className="surface-0 text-700 text-center pb-4">
                    {isApproving ? (
                        <>
                            <div className="text-center">
                                <div className="text-primary"><i className="pi pi-spin pi-spinner mb-4" style={{ fontSize: '4rem' }}></i></div>
                                <div className="text-primary text-xl">Approving registration...</div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="text-center">
                                {/* Icon */}
                                <span className="mb-4 inline-block">
                                    <i className="pi pi-question-circle text-primary" style={{ fontSize: "4rem" }}></i>
                                </span>

                                {/* Title */}
                                <div className="text-primary text-2xl mb-5 font-medium">
                                    Do you want to approve this registration?
                                </div>
                            </div>
                            <Button
                                label="Yes"
                                className="px-8 p-button-rounded mr-4"
                                onClick={handleApprove}
                                disabled={isApproving} // Prevent multiple clicks
                            />
                            <Button
                                label="No"
                                className="px-8 p-button-rounded p-button-outlined"
                                onClick={() => setIsApproveModalOpen(false)}
                                disabled={isApproving}
                            />
                        </>
                    )}
                </div>
            </Dialog>
            {/* Success Modal */}
            <Dialog
                visible={isSuccessModalOpen}
                style={{ width: '40vw', borderRadius: '10px' }}
                onHide={() => setIsSuccessModalOpen(false)}
                className="flex flex-col items-center"
            >

                <div className="surface-0 text-700 text-center pb-4">
                    {/* Icon */}
                    <span className="mb-4 inline-block">
                        <i className="pi pi-check-circle text-primary" style={{ fontSize: '4rem' }}></i>
                    </span>
                    <div className="text-primary text-2xl mb-2 font-medium">
                        Registration Details Successfully Updated!
                    </div>
                    <div className="mb-5">
                        Click the “Okay” button to continue
                    </div>
                    <Button label="Okay" className="px-8 p-button-rounded mr-4" onClick={() => setIsSuccessModalOpen(false)} />
                </div>
            </Dialog>
            {/* Reject Notice Modal */}
            <Dialog
                visible={isApproveModalOpen}
                style={{ width: "50vw", borderRadius: "10px" }}
                onHide={() => setIsApproveModalOpen(false)}
            >
                <div className="surface-0 text-700 text-center pb-4">
                    <span className="mb-4 inline-block">
                        <i className="pi pi-question-circle text-primary" style={{ fontSize: "4rem" }}></i>
                    </span>
                    <div className="text-primary text-2xl mb-5 font-medium">
                        {modalType === "query"
                            ? "Do you want to reject this registration?"
                            : "Do you want to approve this registration?"}
                    </div>
                    <Button
                        label="Yes"
                        className="px-8 p-button-rounded mr-4"
                        onClick={handleReject}
                    />
                    <Button
                        label="No"
                        className="px-8 p-button-rounded p-button-outlined"
                        onClick={() => setIsApproveModalOpen(false)}
                    />
                </div>
            </Dialog>
            {/* State Reasons for Query Modal */}
            <Dialog
                visible={isQueryModalOpen}
                style={{ width: "50vw", borderRadius: "10px" }}
                onHide={() => setIsQueryModalOpen(false)}
            >
                {isQuerying ? (
                    <div className="text-center mb-4">
                        <div className="text-primary">
                            <i className="pi pi-spin pi-spinner mb-4" style={{ fontSize: '4rem' }}></i>
                        </div>
                        <div className="text-primary text-xl">Querying registration...</div>
                    </div>
                ) : (
                    <>
                        <div className="text-primary text-xl mb-4 font-medium text-left">
                            State Reasons for Query
                        </div>
                        <textarea
                            rows="5"
                            maxLength="500"
                            placeholder="Type here..."
                            value={queryReason}
                            onChange={(e) => setQueryReason(e.target.value)}
                            className="w-full p-3 border-1 border-gray-300 border-round text-700 bg-gray-100"
                        ></textarea>
                        <div className="text-right mt-2 text-xs text-gray-500">0/500</div>
                    </>
                )}
                <div className="surface-0 text-700 text-center">

                    <div className="flex justify-content-center">
                        <Button
                            label="Submit"
                            className="px-8 p-button-rounded mr-3"
                            disabled={!queryReason.trim()} // Disable if no reason is provided
                            onClick={handleQuerySubmit}
                        />
                        <Button
                            label="Cancel"
                            className="px-8 p-button-rounded p-button-outlined"
                            onClick={() => setIsQueryModalOpen(false)}
                        />
                    </div>
                </div>
            </Dialog>
            <Button label="Back" icon="pi pi-arrow-left" className="p-button-text p-button-secondary mb-2 p-button-rounded" onClick={goBack} />
            <div className="px-2">
                <div className="bg-yellow-600 px-4 py-2 shadow-2 border-round mb-4">
                    <div className="text-white font-bold">Registration Details</div>
                </div>

                <div>
                    {/* Child's Data */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        {/* Buttons */}
                        <div className="mb-4">
                            <div className="font-bold text-800">Particulars of Child</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("Surname of Child", details?.childSurname)}
                            {renderDataField("First Name of Child", details?.childFirstName)}
                            {renderDataField("Middle Name of Child", details?.childMiddleName)}
                            {renderDataField("Reference", details?.reference)}
                            {renderDataField("Date of Birth", details?.dateOfBirth)}
                            {renderDataField("Sex", details?.childSexData?.gender)}
                            {renderDataField("Place of Birth", details?.placeOfBirthData?.BirthPlace_Desc)}
                            {renderDataField("Birth Type", details?.birthTypeData?.Description)}
                        </div>
                        <div className="text-600 text-xs">Child Face</div>
                        {isLoadingFileUrl ? (
                            <Skeleton width="150px" height="150px" />
                        ) : faceUrl ? (
                            <Image src={faceUrl} zoomSrc={faceUrl} alt="Child Face" preview width="150px" height="150px" />
                        ) : (
                            <p>No face image available.</p>
                        )}
                    </div>

                    {/* Mother's Data */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Particulars of Mother</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("First Name", details?.motherFirstName)}
                            {renderDataField("Surname", details?.motherSurname)}
                            {renderDataField("Maiden Name", details?.motherMaidenName)}
                            {renderDataField("Age at Birth", details?.motherAgeAtBirth)}
                            {renderDataField("Marital Status", details?.motherMaritalStatusData?.Status_Desc)}
                            {renderDataField("Phone Number", details?.motherPhoneNumber)}
                            {renderDataField("Address", details?.motherResidenceAddress)}
                            {renderDataField("Nationality", details?.motherCountryOfResidenceData?.Country_Name)}
                        </div>
                    </div>

                    {/* Father's Data */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Particulars of Father</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("First Name", details?.fatherFirstName)}
                            {renderDataField("Surname", details?.fatherSurname)}
                            {renderDataField("Age at Birth", details?.fatherAgeAtBirth)}
                            {renderDataField("Marital Status", details?.fatherMaritalStatusData?.Status_Desc)}
                            {renderDataField("Phone Number", details?.fatherPhoneNumber)}
                            {renderDataField("Address", details?.fatherResidenceAddress)}
                            {renderDataField("Nationality", details?.fatherCountryOfResidenceData?.Country_Name)}
                        </div>
                    </div>

                    {/* Additional Information */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Additional Information</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid">
                            {renderDataField("NIN", details?.nin)}
                            {renderDataField("Legacy Certificate Number", details?.legacyCertNumber)}
                            {renderDataField(
                                "Approval Status",
                                details?.approvedStatus === "1"
                                    ? "Approved"
                                    : details?.approvedStatus === "2"
                                        ? "Queried"
                                        : "Pending"
                            )}
                        </div>
                    </div>

                    {/* Uploaded Document Information */}
                    <div className="surface-0 px-4 py-4 shadow-2 border-round mb-4">
                        <div className="mb-4">
                            <div className="font-bold text-800">Uploaded Document</div>
                            <div className="bg-blue-200" style={{ height: '2px', width: '70px' }}></div>
                        </div>
                        <div className="grid capitalize">
                            {renderDataField("Document Type", details?.supportingDocument?.[0]?.type)}
                            <div>
                                {/* Render preview images */}
                                <div className="text-600 text-xs">Document Preview</div>
                                {isLoadingFileUrl ? (
                                    <Skeleton width="150px" height="150px" />
                                ) : documentUrl ? (
                                    <Image src={documentUrl} zoomSrc={documentUrl} alt="Supporting Document" preview width="150px" height="150px" />
                                ) : (
                                    <p>No document available.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>




                <div className="">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>
                        </div>
                        <div className="mt-3 lg:mt-0">
                            {details?.approvedStatus === "0" && !details?.queried && (
                                <>
                                    <Button
                                        label="Approve"
                                        className="mr-2 p-button-raised"
                                        style={{ width: "150px" }}
                                        onClick={() => {
                                            setModalType("approve");
                                            setIsApproveModalOpen(true);
                                        }}
                                    />
                                    <Button
                                        label="Query"
                                        className="p-button-danger p-button-raised"
                                        style={{ width: "150px" }}
                                        onClick={() => {
                                            setModalType("query");
                                            setIsApproveModalOpen(true);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeathCertificateReissuanceDetails;