import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../redux/birthSlice";
import authFetch from "../axios";
import { SerialNumberTemplate, statusFormat } from "../utils";
import { useTableSerial } from "../helpers/hooks/useTableSerial";
import { useDebounce } from "../helpers/hooks/useDebounce";
import Filter3 from "../components/Filter3";
import { Skeleton } from 'primereact/skeleton';
import { Button } from "primereact/button";

const DeathCertificateReissuanceList = () => {
  const [rez, setRez] = useState({});
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isComingSoon, setIsComingSoon] = useState(false);
  const dispatch = useDispatch();
  const [basicFirst, setBasicFirst] = useState(0);

  const navigate = useNavigate();
    const goBack = () => navigate(-1);

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    dispatch(changePage(event.page + 1));
  };

  const { search, result_per_page, page, self, type } = useSelector(
    (state) => state.birth
  );
  const { value: debouncedValue } = useDebounce(search, 1000);

  useEffect(() => {

    window.scrollTo({ top: 0, behavior: "smooth" });

    const getStatz = async () => {
      try {
        const statistics = await authFetch.get(
          "/certificate-reissuance/stats?certType=death_reissuance"
        );
        setRez(statistics.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRecords = async () => {
      setIsLoading(true);
      setIsComingSoon(false);

      try {
        const response = await authFetch.get(
          `/certificate-reissuance/list?certType=death_reissuance&search=${debouncedValue}&result_per_page=${result_per_page}&page=${page}&type=${type || "pending"}`
        );

        setRecords(response?.data?.result);
        setTotalRecords(response?.data?.pagination?.total);
      } catch (error) {
        if (error.response?.data?.message === "Coming soon!") {
          setIsComingSoon(true);
        } else {
          console.error(error); // Log other errors
        }
      } finally {
        setIsLoading(false);
      }
    };

    getStatz();
    fetchRecords();
  }, [debouncedValue, self, result_per_page, page, type]);

  const ComingSoon = () => (
    <div className="text-center p-5">
      <h1 className="text-gray-800 text-3xl font-bold mb-4">Coming Soon!</h1>
      <p className="text-gray-600 text-lg">
        This feature is not yet available. Please check back later.
      </p>
      <Button
        label="Go Back"
        icon="pi pi-arrow-left"
        className="p-button-outlined p-button-secondary mt-3 p-button-rounded"
        onClick={goBack}
      />
    </div>
  );

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className="btn btn-primary text-primary font-bold"
        to={`/birth-cert-reissuance/details/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };

  const fullname = (rowData) => (
    <p className="font-bold">{rowData?.firstName ?? ""} {" "} {rowData?.middleName ?? ""} {" "} {rowData?.surname ?? ""}</p>
  );
  const refNumber = (rowData) => (
    <p className="font-bold">{rowData?.reference ?? ""}</p>
  );
  const gender = (rowData) => (
    <p className="font-bold">{rowData.childSexData?.gender ?? ""}</p>
  );

  const renderHeader1 = () => <Filter3 />;
  const header1 = renderHeader1();

  const { serials } = useTableSerial({
    data: records,
    currentPage: page,
    pageSize: result_per_page,
  });


  return (
    <>
      {isComingSoon ? (
        <ComingSoon />
      ) : (
        <>
          <div className="bg-white text-gray-900 flex justify-content-between lg:justify-content-center align-items-center flex-wrap shadow-1 border-round mb-5">
            {isLoading ? (
              <div className="p-3 flex">
                <Skeleton borderRadius="16px" width="20rem" height="2rem" className="mr-2" />
                <Skeleton borderRadius="16px" width="5rem" height="2rem" />
              </div>
            ) : (
              <div className="align-items-center hidden lg:flex">
                <p className="text-gray-800 text-lg">
                  Total Birth Certificates Reissued:{" "}
                  <span className="font-bold">{rez?.Total ?? 0}</span>
                </p>
              </div>
            )}
          </div>

          <div className="grid">
            {isLoading ? (
              Array(3).fill(null).map((_, i) => (
                <div key={i} className="col-12 md:col-6 lg:col-3">
                  <Skeleton width="100%" height="7rem" className="p-3 border-round shadow-1" />
                </div>
              ))
            ) : (
              <>
                <div className="col-12 md:col-6 lg:col-3">
                  <div className="surface-0 shadow-1 border-round p-3 border-1 border-50">
                    <div className="flex justify-content-between">
                      <div>
                        <span className="block text-700 text-sm mb-2">Pending Approvals</span>
                        <div className="text-900 font-bold text-xl">{rez?.Pending ?? 0}</div>
                      </div>
                      <div className="flex align-items-center justify-content-center bg-yellow-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                        <i className="pi pi-desktop text-yellow-500 text-2xl"></i>
                      </div>
                    </div>
                    <div className="lga-line-container">
                      <svg
                        className="lga-line"
                        width="100"
                        height="7"
                        viewBox="0 0 100 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="100" height="7" rx="2.5" fill="url(#staticGradient)" />
                        <defs>
                          <radialGradient
                            id="staticGradient"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(48 2.5) rotate(90) scale(2.5 112.412)"
                          >
                            <stop stopColor="#fbc02d" />
                            <stop
                              offset="0.62645"
                              stopColor="#fbc02d"
                              stopOpacity="0"
                            />
                          </radialGradient>
                        </defs>
                      </svg>
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#fbc02d" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                  <div className="surface-0 shadow-1 border-round p-3 border-1 border-50">
                    <div className="flex justify-content-between">
                      <div>
                        <span className="block text-700 text-sm mb-2">Approved Applications</span>
                        <div className="text-900 font-bold text-xl">{rez?.Approved ?? 0}</div>
                      </div>
                      <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                        <i className="pi pi-desktop text-green-500 text-2xl"></i>
                      </div>
                    </div>
                    <div className="lga-line-container">
                      <svg
                        className="lga-line"
                        width="100"
                        height="7"
                        viewBox="0 0 100 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="100" height="7" rx="2.5" fill="url(#staticGradient2)" />
                        <defs>
                          <radialGradient
                            id="staticGradient2"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(48 2.5) rotate(90) scale(2.5 112.412)"
                          >
                            <stop stopColor="#0e7706" />
                            <stop
                              offset="0.62645"
                              stopColor="#0e7706"
                              stopOpacity="0"
                            />
                          </radialGradient>
                        </defs>
                      </svg>
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#0e7706" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                  <div className="surface-0 shadow-1 border-round p-3 border-1 border-50">
                    <div className="flex justify-content-between">
                      <div>
                        <span className="block text-700 text-sm mb-2">Queried Records</span>
                        <div className="text-900 font-bold text-xl">{rez?.Queried ?? 0}</div>
                      </div>
                      <div className="flex align-items-center justify-content-center bg-red-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                        <i className="pi pi-desktop text-red-500 text-2xl"></i>
                      </div>
                    </div>
                    <div className="lga-line-container">
                      <svg
                        className="lga-line"
                        width="100"
                        height="7"
                        viewBox="0 0 100 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="100" height="7" rx="2.5" fill="url(#staticGradient3)" />
                        <defs>
                          <radialGradient
                            id="staticGradient3"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(48 2.5) rotate(90) scale(2.5 112.412)"
                          >
                            <stop stopColor="#ff4032" />
                            <stop
                              offset="0.62645"
                              stopColor="#ff4032"
                              stopOpacity="0"
                            />
                          </radialGradient>
                        </defs>
                      </svg>
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#ff4032" />
                      </svg>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="grid mt-3">
            <div className="col-12 lg:col-12">
              <div className="surface-0 shadow-1 border-round p-3 border-1 border-50">
                <div className="mb-3 flex align-items-center justify-content-between">
                  <span className="text-900 font-bold text-xl capitalize">
                    {type} Registrations
                  </span>
                </div>
                {isLoading ? (
                  <DataTable
                    header={header1}
                    value={Array(8).fill({})}
                    className="p-datatable-striped"
                    stripedRows
                    responsiveLayout="stack"
                    rowHover
                  >
                    <Column
                      field="id"
                      header="S/N"
                      body={<Skeleton borderRadius="16px" width="80%" />}
                    ></Column>
                    <Column
                      field="fullname"
                      header="Full Name"
                      body={<Skeleton borderRadius="16px" width="100%" />}
                    ></Column>
                    <Column
                      field="refNumber"
                      header="Registration ID"
                      body={<Skeleton borderRadius="16px" width="80%" />}
                    ></Column>
                    <Column
                      field="gender"
                      header="Gender"
                      body={<Skeleton borderRadius="16px" width="60%" />}
                    ></Column>
                    <Column
                      field="dateOfBirth"
                      header="Date Of Birth"
                      body={<Skeleton borderRadius="16px" width="80%" />}
                    ></Column>
                    <Column
                      field="status"
                      header="Status"
                      body={<Skeleton borderRadius="16px" width="60%" />}
                    ></Column>
                    <Column
                      field="action"
                      header="Action"
                      body={<Skeleton borderRadius="16px" width="50%" />}
                    ></Column>
                  </DataTable>
                ) : (
                  <DataTable
                    value={records}
                    stripedRows
                    responsiveLayout="stack"
                    header={header1}
                    rowHover
                  >
                    <Column
                      field="id"
                      header="S/N"
                      body={(cell) =>
                        SerialNumberTemplate(cell, serials, records)
                      }
                    ></Column>
                    <Column
                      body={fullname}
                      header="Full Name"
                    ></Column>
                    <Column
                      body={refNumber}
                      header="Registration ID"
                    ></Column>
                    <Column
                      body={gender}
                      header="Gender"
                    ></Column>
                    <Column
                      field="dateOfBirth"
                      header="Date Of Birth"
                    />
                    <Column
                      body={(rowData) => statusFormat(rowData?.approvedStatus)}
                      header="Status"
                    ></Column>
                    <Column
                      field=""
                      header="Action"
                      body={statusBodyTemplate2}
                    />
                  </DataTable>
                )}

                <Tooltip target=".export-buttons>button" position="bottom" />
                <Paginator
                  first={basicFirst}
                  rows={result_per_page}
                  totalRecords={totalRecords}
                  onPageChange={onBasicPageChange}
                ></Paginator>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeathCertificateReissuanceList;
